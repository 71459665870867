<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('nfcId')" prop="nfcId" >
        <a-input v-model="form.nfcId" :placeholder="$t('请输入nfcId')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('nfc名称')" prop="nfcName" >
        <a-input v-model="form.nfcName" :placeholder="$t('请输入nfc名称')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('绑定设备')" prop="doorId" >
        <a-select :placeholder="$t('请选择')" v-model="form.doorId">
          <a-select-option v-for="(item,key) in devList" :key="key" :value="item.id">
            {{ item.doorName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="门id" prop="doorId" >
        <a-input v-model="form.doorId" placeholder="请输入门id" />
      </a-form-model-item> -->
      <!-- <a-button type="primary" :loading="submitLoading" @click="show">绑定设备</a-button>
      <template>
        <a-list item-layout="horizontal" :data-source="checkList">
          <a-list-item slot="renderItem" slot-scope="item, index">
            已绑设备名：{{ item.doorName }} 设备编码：{{ item.devCode }}
          </a-list-item>
        </a-list>
      </template> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
        <!-- 设备列表 -->
        <a-modal :title="$t('绑定设备')" :visible="addressVisible" width="1300px" footer="" @cancel="addressVisible = false">
      <address-list ref="addressPage" @ok="changeAddress" />
    </a-modal>
  </a-drawer>
</template>

<script>
import { getNfc, addNfc, updateNfc } from '@/api/door/nfc'
import { pageDevice } from '@/api/door/device'
import AddressList from './addressList'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    AddressList
  },
  data () {
    return {
      ids: [],
      devList: [],
      addressVisible: false,
      submitLoading: false,
      formTitle: '',
      checkList: [],
      // 表单参数
      form: {
        id: null,

        checkList: [],
        nfcId: null,

        nfcName: null,

        doorId: undefined,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        nfcId: [
          { required: true, message: 'nfcId不能为空', trigger: 'blur' }
        ],
        nfcName: [
          { required: true, message: 'nfc名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.rules={
      nfcId: [
        { required: true, message: this.$t('nfcId不能为空'), trigger: 'blur' }
      ],
      nfcName: [
        { required: true, message: this.$t('nfc名称不能为空'), trigger: 'blur' }
      ]
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    changeAddress(list) {
      // console.log("传过来的" + list)
      this.checkList = list
      this.devIdList = list.map(item => item.id)
      this.addressVisible = false;
      console.log("传过来的" + this.devIdList)
    },
    show() {
      this.addressVisible = true;
      this.$nextTick(() => {
        // this.form.userId=this.form.userResultList.map(e=> {return e.id});
        console.log("form", this.form)
        this.$refs.addressPage.init(this.form.deviceResults.map(e => { return e.id }))
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        nfcId: null,
        nfcName: null,
        doorId: undefined,
        createTime: null,
        checkList: []
      }
      pageDevice({pageNum: 1,pageSize: 999999}).then(res=>{
        // console.log('pageDevice',res.data.records)
        this.devList = res.data.records
      })
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getNfc({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.checkList = response.data.deviceResults
        this.formTitle = this.$t('修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log("提交" + this.devIdList)
      this.form.devIdList = this.devIdList
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateNfc(this.form).then(response => {
              this.$message.success(
                this.$t('修改成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addNfc(this.form).then(response => {
              this.$message.success(
                this.$t('新增成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
