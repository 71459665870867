<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('门名称')" prop="doorName">
                <a-input v-model="queryParam.doorName" :placeholder="$t('请输入门名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('设备编码')" prop="devCode">
                  <a-input v-model="queryParam.devCode" :placeholder="$t('请输入设备编码')" allow-clear/>
                </a-form-item>
              </a-col>
            
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{ $t('重置') }}</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('收起') : $t('展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <!-- <div class="table-operations">
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['door:device:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['door:device:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div> -->

      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
                :row-selection="{ selectedRowKeys: ids, onChange: onSelectChange }" :pagination="false" 
                :bordered="tableBordered">
      
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <!-- <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['door:device:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['door:device:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['door:device:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['door:device:remove']">
            <a-icon type="delete" />删除
          </a>
        </span> -->
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => $t('共') + total + $t('条')"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <div style="margin-top: 20px; display: flex; justify-content: flex-end;">
  <a-button type="primary" @click="handleOk()">{{ $t('确认') }}</a-button>
</div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDevice,listDevice, delDevice } from '@/api/door/device'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Device',
  components: {
  },
  mixins: [tableMixin],
  data () {
    return {
      idList:[],
      checkList: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      list: [],
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        doorName: null,
        doorType: null,
        devCode: null,
        onlineTime: null,
        offTime: null,
        online: null,
        devIccId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
    
        {
          title: this.$t('门名称'),
          dataIndex: 'doorName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('门类型'),
          dataIndex: 'doorType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('设备编码'),
          dataIndex: 'devCode',
          ellipsis: true,
          align: 'center'
        },
     
        {
          title: this.$t('设备ICCID'),
          dataIndex: 'devIccId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('添加时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
        
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init(ids) {
            this.ids = ids
            console.log("userId",this.ids)
        },
    handleOk () {
      if (this.checkList) {
        this.$emit('ok', this.checkList)
        console.log(this.checkList)
      } else {
        this.$message.info('请选择设备')
      }
    },
    /** 查询设备信息列表 */
    getList () {
      this.loading = true
     pageDevice(this.queryParam).then(response => {
        this.list = response.data.records
        
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        doorName: undefined,
        doorType: undefined,
        devCode: undefined,
        onlineTime: undefined,
        offTime: undefined,
        online: undefined,
        devIccId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.checkList=selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('确认删除所选中数据?'),
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delDevice(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                this.$t('删除成功'),
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('是否确认导出?'),
        content: this.$t('此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('door/device/export', {
            ...that.queryParam
          }, `设备信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
