import request from '@/utils/request'


// 查询设备信息列表
export function listDevice(query) {
  return request({
    url: '/door/door-device/list',
    method: 'get',
    params: query
  })
}

// 查询设备信息分页
export function pageDevice(query) {
  return request({
    url: '/door/door-device/page',
    method: 'get',
    params: query
  })
}

// 查询设备信息详细
export function getDevice(data) {
  return request({
    url: '/door/door-device/detail',
    method: 'get',
    params: data
  })
}

// 新增设备信息
export function addDevice(data) {
  return request({
    url: '/door/door-device/add',
    method: 'post',
    data: data
  })
}

// 修改设备信息
export function updateDevice(data) {
  return request({
    url: '/door/door-device/edit',
    method: 'post',
    data: data
  })
}

// 删除设备信息
export function delDevice(data) {
  return request({
    url: '/door/door-device/delete',
    method: 'post',
    data: data
  })
}

// 获取用户分组列表
export function getUserGroups() {
  return request({
    url: '/door/user-groups',
    method: 'get'
  })
}
